<!-- 登录页面 -->
<template>
  <div class="login">
    <el-card class="login-card">
      <h2>青年大学习管理面板 | 登录</h2>
      <el-divider></el-divider>
      <el-form
      :model="loginform"
      :rules="rules"
      ref="loginform"
      label-position="right"
      label-width="60px">
        <el-form-item label="账号" prop="qq">
          <el-input
            type="text"
            v-model="loginform.qq"
            name="username"
            placeholder="请输入账号">
          </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="verify">
          <el-input
            type="password"
            v-model="loginform.verify"
            autocomplete="off"
            placeholder="请输入密码">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="success"
            style="width:240px; margin-left:-60px"
            @click="handleLogin('loginform')">登录
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="footer">
      <p>
        <span>© 2019-2020 </span>
        <span>青年大学习</span>
        <span>管理系统</span>
      </p>
      <p>
        <span>design by </span>
        <span>0o酱</span>
        <span> | 指导教师：王春荣、徐健</span>
      </p>
    </div>
  </div>
</template>

<style>
.footer {
  position: absolute;
  bottom: 40px;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 4px;
}
.footer span {
  font-size: 12px;
  color: #9998;
}

.login {
  top: 0px;
  height: 94vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-card {
  width: 400px;
  text-align: center;
}
</style>

<script>
export default {
  data() {
    return {
      loginform: {
        qq: "",
        verify: ""
      },
      rules: {
        qq: [
          { required: true, message: '请输入账号', trigger: 'blur'}
        ],
        verify: [
          { required: true, message: '请输入密码', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    handleLogin(formName) {
      const Base64 = require('js-base64')
      // const sha256 = require('js-sha256').sha256
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.loginform.password = sha256(this.loginform.password)
          this.$http
            .post("login", JSON.stringify(this.loginform))
            .then((response) => {
              if (response.data.code == 200) {
                this.$cookies.set('token', response.data.data.token);
                this.$cookies.set('userId', response.data.data.qq);
                this.$cookies.set('groupid', response.data.data.groupid);
                this.$cookies.set('userName', Base64.encode(response.data.data.name));
                this.$router.push({path: '/index'});
                // urldecode username
                this.$notify({
                  title: '登录成功',
                  message: '欢迎回来，' + response.data.data.name,
                  type: 'success'
                });
              } else {
                if (response.data.code == 1003) {
                  this.$notify({
                    title: '登录失败',
                    message: '账号或密码错误',
                    type: 'error'
                  });
                } else {
                  this.$notify({
                    title: '登录失败',
                    message: '未知错误',
                    type: 'error'
                  });
                }
              }
            }).catch(function (error) {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    }
  },
  created() {
    if (
      this.$cookies.get('token') &&
      this.$cookies.get('userId') && 
      this.$cookies.get('userName') &&
      this.$cookies.get('groupid')
      ) {
      this.$router.push({path: '/index'});
    } else {
      this.$cookies.remove('token');
      this.$cookies.remove('userId');
      this.$cookies.remove('userName');
      this.$cookies.remove('groupid');
    }
  }
}
</script>